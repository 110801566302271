import axiosInstance from "../../utils/AxiosInstance";
export async function resetPassword(email: string, token: string, password: string): Promise<boolean> {
  try {
    const response = await axiosInstance.post<boolean>("/reset-password", { email, token, password });
    return response.data;
  } catch (error) {
    console.error("Password reset failed", error);
    return false;
  }
}

export async function validateResetToken(email: string, token: string): Promise<boolean> {
  try {
    const response = await axiosInstance.post<boolean>("/validate-reset-token", { email, token });
    return response.data;
  } catch (error) {
    console.error("Token validation failed", error);
    throw error;
  }
}
