import React, { useRef, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaijaModal from "../../components/MaijaModal";
import { useNavigate } from "react-router-dom";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animationData from "../../assets/lottie/profile_complete.json";
import { APPLICATION_ROUTE } from "../../Routes";

interface CompletedProfileModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const CompletedProfileModal: React.FC<CompletedProfileModalProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(0.6);
    }
  }, []);

  const handleNavigateToApplications = () => {
    navigate(APPLICATION_ROUTE.path);
    handleClose();
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} sx={{ width: "60vw", maxWidth: "800px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Lottie
          animationData={animationData}
          loop={false}
          autoplay={true}
          initialSegment={[0, 46]}
          lottieRef={lottieRef}
          style={{ height: 200, width: 200 }}
        />
        <Typography variant="h6" component="h2">
          {t("profilePage.completedProfileModal.congratulations")}
        </Typography>
        <Typography sx={{ mt: 2 }}>{t("profilePage.completedProfileModal.profileCompletionMessage")}</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 5, justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleNavigateToApplications} sx={{ mr: 2 }}>
            {t("profilePage.completedProfileModal.goToApplications")}
          </Button>
          <Button color="primary" onClick={handleClose}>
            {t("generic.close")}
          </Button>
        </Box>
      </Box>
    </MaijaModal>
  );
};

export default CompletedProfileModal;
