import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { RootState } from "../../store";
import { postSelfAssessmentAnswer as apiPostSelfAssessmentAnswer } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setSelfAssessmentQuestionAnswer } from "../../reducers/profilePageSlice";
import { SelfAssessmentQuestion } from "../../types/SelfAssessment";
import { useTranslation } from "react-i18next";

const SelfAssessmentComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const questions = useSelector((state: RootState) => state.profilePage.selfAssessmentQuestions);

  const onAnswer = async (id: string, answer: string) => {
    try {
      dispatch(
        setSelfAssessmentQuestionAnswer({
          questionId: id,
          answer: answer,
        }),
      );
      await apiPostSelfAssessmentAnswer({
        questionId: id,
        answer: answer,
      });
      dispatch(
        showSnackbar({
          message: t("profilePage.selfAssessment.answerSuccess"),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("profilePage.selfAssessment.answerError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {questions && questions.length > 0 ? <QuestionsList questions={questions} onAnswer={onAnswer} /> : <EmptyState />}
    </Box>
  );
};

interface QuestionsListProps {
  questions: SelfAssessmentQuestion[];
  onAnswer: (id: string, answer: string) => void;
}

const QuestionsList: React.FC<QuestionsListProps> = ({ questions, onAnswer }) => {
  const { t } = useTranslation();
  const firstUnansweredIndex = questions.findIndex((question) => !question.answer);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    return firstUnansweredIndex !== -1 ? firstUnansweredIndex : 0;
  });

  const [isReviewing, setIsReviewing] = useState(() => {
    return firstUnansweredIndex === -1;
  });
  const [fromReview, setFromReview] = useState(false);

  useEffect(() => {
    const allAnswered = questions.every((question) => question.answer);
    if (allAnswered) {
      setIsReviewing(true);
    }
  }, [questions]);

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  const handleRadioChange = (questionId: string, answer: string) => {
    onAnswer(questionId, answer);
  };

  const handleNext = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinish = () => {
    setIsReviewing(true);
  };

  const handleQuestionClick = (index: number) => {
    setCurrentQuestionIndex(index);
    setIsReviewing(false);
    setFromReview(true);
  };

  const handleBackToReview = () => {
    setIsReviewing(true);
    setFromReview(false);
  };

  if (isReviewing) {
    return <ReviewList questions={questions} onQuestionClick={handleQuestionClick} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("profilePage.selfAssessment.title")}
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        {t("profilePage.selfAssessment.questionCounter")} {currentQuestionIndex + 1}{" "}
        {t("profilePage.selfAssessment.of")} {totalQuestions}
      </Typography>

      <Box sx={{ width: "100%", mb: 3 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>

      <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
        {currentQuestion.question}
      </Typography>

      <RadioGroup
        name={`question-${currentQuestion.id}`}
        value={currentQuestion.answer || ""}
        onChange={(event) => handleRadioChange(currentQuestion.id, (event.target as HTMLInputElement).value)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          mb: 3,
        }}
      >
        <Box>
          {currentQuestion?.allowedAnswers?.map((answer, index) => (
            <FormControlLabel
              key={index}
              value={answer}
              control={<Radio size="small" />}
              label={answer}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: 1,
              }}
              data-cy={`answer-${index}`}
            />
          ))}
        </Box>
      </RadioGroup>
      {fromReview ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={handleBackToReview} sx={{ width: "100%" }}>
            {t("generic.back")}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={handleBack} disabled={currentQuestionIndex === 0} sx={{ width: "45%" }}>
            {t("generic.back")}
          </Button>
          <Button
            variant="contained"
            onClick={currentQuestionIndex === totalQuestions - 1 ? handleFinish : handleNext}
            disabled={!currentQuestion.answer}
            sx={{ width: "45%" }}
            data-cy="next-button"
          >
            {currentQuestionIndex === totalQuestions - 1 ? t("profilePage.selfAssessment.finish") : t("generic.next")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

interface ReviewListProps {
  questions: SelfAssessmentQuestion[];
  onQuestionClick: (index: number) => void;
}

const ReviewList: React.FC<ReviewListProps> = ({ questions, onQuestionClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.selfAssessment.reviewTitle")}
      </Typography>
      <List sx={{ flex: 1 }}>
        {questions.map((question, index) => (
          <div key={question.id}>
            <ListItem
              button
              onClick={() => onQuestionClick(index)}
              sx={{ display: "flex", alignItems: "flex-start", py: 1, pl: 0 }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {index + 1}. {question.question}
                </Typography>
                <ListItemText
                  primary={
                    question.answer
                      ? `${t("profilePage.selfAssessment.answer")}: ${question.answer}`
                      : t("profilePage.selfAssessment.noAnswer")
                  }
                />
              </Box>
            </ListItem>
            {index < questions.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Box>
  );
};

const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.selfAssessment.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.selfAssessment.emptyStateMessage")}
      </Typography>
    </Box>
  );
};

export default SelfAssessmentComponent;
