import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Autocomplete,
  Pagination,
  Chip,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { JobAdListItem } from "../../../types/JobAdListItem";
import { getJobSearchPreferences, getJobSearchSuggestions, searchJobAds } from "../ApplicationRepository";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  setCurrentPage,
  setSearchQuery,
  clearFilters,
  setSearchResults,
  setSearchTotal,
  setSearchMunicipalityFilter,
  setSearchRegionFilter,
} from "../../../reducers/applicationPageSlice";
import { LocationFilterSelector } from "./LocationFilterSelector";
import { OccupationFilterSelector } from "./OccupationFilterSelector";
import { OpenInNew, WorkOutline } from "@mui/icons-material";
import { RegionFilter } from "../../../types/RegionFilter";
import { getLocationFiltersFromNames } from "../../../types/LocationFilterSelection";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MunicipalityFilter } from "../../../types/MunicipalityFilter";

export interface JobListAdSearchFormProps {
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

const JobListAdSearchForm: React.FC<JobListAdSearchFormProps> = (props: JobListAdSearchFormProps) => {
  const PAGE_SIZE = 10;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cachedQuery = useAppSelector((state) => state.applicationPage.searchQuery);
  const [query, setQuery] = useState<string>(cachedQuery || "");
  const [selectedJobTag, setSelectedJobTag] = useState<string | null>(null);

  const currentPage = useAppSelector((state) => state.applicationPage.searchCurrentPage);
  const searchTotal = useAppSelector((state) => state.applicationPage.searchTotal);
  const regionFilter = useAppSelector((state) => state.applicationPage.searchRegionFilter);
  const municipalityFilter = useAppSelector((state) => state.applicationPage.searchMunicipalityFilter);
  const occupationGroupFilter = useAppSelector((state) => state.applicationPage.searchOccupationGroupFilter);
  const occupationFilter = useAppSelector((state) => state.applicationPage.searchOccupationFilter);

  const [preferredJobs, setPreferredJobs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState<boolean>(false);
  const [displayLocationFilterSwitch, setDisplayLocationFilterSwitch] = useState<boolean>(true);

  const [jobAds, setJobAds] = useState<JobAdListItem[]>(
    useAppSelector((state) => state.applicationPage.searchResults || []),
  );

  const handleSearch = async (searchQuery = query, startIndex = 0) => {
    setIsLoading(true);

    const regionIds = regionFilter?.map((region) => region.id);
    const municipalityIds = municipalityFilter?.map((municipality) => municipality.id) || [];

    const occupationGroupIds = occupationGroupFilter?.map((group) => group.id);
    let occupationIds = occupationFilter?.map((occupation) => occupation.id) || [];

    if (occupationGroupFilter && occupationGroupFilter.length > 0) {
      const groupOccupationIds = occupationGroupFilter.flatMap((group) => group.occupations.map((o) => o.id));
      occupationIds = occupationIds.filter((id) => !groupOccupationIds.includes(id));
    }

    try {
      const results = await searchJobAds({
        query: searchQuery,
        regions: regionIds,
        municipalities: municipalityIds,
        occupationGroups: occupationGroupIds,
        occupationFields: occupationIds,
        maxRecords: PAGE_SIZE,
        startIndex: startIndex,
      });

      if (!results) {
        return;
      }

      if (startIndex === 0) {
        dispatch(setCurrentPage(1));
      }

      dispatch(setSearchQuery(searchQuery));
      dispatch(setSearchResults(results.ads));
      dispatch(setSearchTotal(results.numberOfAds));

      setJobAds(results.ads);
    } catch (error) {
      console.error("Error searching job ads:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (query && query.length > 2) {
      const fetchSuggestions = async () => {
        setIsSuggestionsLoading(true);
        try {
          const result = await getJobSearchSuggestions(query);
          setSuggestions(result.suggestions);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
        setIsSuggestionsLoading(false);
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const initJobSearchPreferences = async () => {
    try {
      const data = await getJobSearchPreferences();
      const locations = data.jobSearchLocation;

      setPreferredJobs(
        [data.firstPreferredJob, data.secondPreferredJob, data.thirdPreferredJob].filter(
          (job): job is string => job != null && job !== "",
        ),
      );

      setLocationFilters(locations);
      if (locations.length == 0) {
        setDisplayLocationFilterSwitch(false);
      }
    } catch (error) {
      console.error("Error fetching job search preferences:", error);
    }
  };

  const setLocationFilters = (municipalities: string[]) => {
    const updatedRegions: RegionFilter[] = [...regionFilter];
    const updatedMunicipalities: MunicipalityFilter[] = [...municipalityFilter];
    const selection = getLocationFiltersFromNames(municipalities);
    selection.regions.forEach(function (region: RegionFilter) {
      updatedRegions.push(region);
    });
    selection.municipalities.forEach(function (municipality: MunicipalityFilter) {
      updatedMunicipalities.push(municipality);
    });
    dispatch(setSearchRegionFilter(updatedRegions));
    dispatch(setSearchMunicipalityFilter(updatedMunicipalities));
  };

  const resetLocationFilters = () => {
    const updatedRegions: RegionFilter[] = [];
    const updatedMunicipalities: MunicipalityFilter[] = [];
    dispatch(setSearchRegionFilter(updatedRegions));
    dispatch(setSearchMunicipalityFilter(updatedMunicipalities));
  };

  const handleTagClick = (tag: string) => {
    if (selectedJobTag === tag) {
      setSelectedJobTag(null);
      setQuery("");
      handleSearch("", 0);
    } else {
      setSelectedJobTag(tag);
      setQuery(tag);
      handleSearch(tag, 0);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setCurrentPage(value));
    handleSearch(query, (value - 1) * PAGE_SIZE);
  };

  useEffect(() => {
    initJobSearchPreferences();
  }, []);

  useEffect(() => {
    handleSearch(query, 0);
  }, [regionFilter, municipalityFilter]);

  return (
    <Card sx={{ margin: "auto", mb: 2, p: 3, maxWidth: 800, borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h6" align="left" sx={{ mb: 4, mt: -2, fontWeight: 550 }}>
          {t("application.jobSearch.title")}
        </Typography>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { mb: 2, width: "100%" },
            "& .MuiButton-root": { padding: "3 6" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Autocomplete
              id="search-query-field"
              options={suggestions}
              loading={isSuggestionsLoading}
              value={query}
              onInputChange={(event, value, reason) => {
                setQuery(value || "");
                if (reason === "input" || reason === "clear") {
                  setSelectedJobTag(null);
                }
              }}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("application.jobSearch.queryLabel")}
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch(query, 0);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isSuggestionsLoading && suggestions.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSearch(query, 0)}
              sx={{ ml: 3, height: 56, px: 3, py: 4 }}
              startIcon={<SearchIcon />}
              disabled={isLoading}
            >
              {t("application.jobSearch.searchButton")}
            </Button>
          </Box>
          <Box sx={{ mb: 5, mt: 2, display: "flex", gap: 1, flexWrap: "wrap" }}>
            {preferredJobs.map((job, index) => (
              <Chip
                key={index}
                label={job}
                clickable
                color={selectedJobTag === job ? "primary" : "default"}
                onClick={() => handleTagClick(job)}
                sx={{ cursor: "pointer" }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 2,
            }}
          >
            {displayLocationFilterSwitch && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  border: "0.5px solid",
                  borderColor: municipalityFilter.length > 0 ? "primary.main" : "text.default",
                  padding: 0.75,
                  borderRadius: 2,
                  mr: 2,
                }}
                onClick={() => {
                  if (municipalityFilter.length > 0) {
                    resetLocationFilters();
                  } else {
                    initJobSearchPreferences();
                  }
                }}
              >
                <Typography sx={{ ml: 2, mr: 1 }}>{t("application.jobSearch.locationFilter")}</Typography>
                <Switch
                  checked={municipalityFilter.length > 0}
                  onChange={() => {}}
                  name="locationFilterToggle"
                  color="primary"
                />
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 2 }}>
                <LocationFilterSelector />
              </Box>
              <OccupationFilterSelector />
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  dispatch(clearFilters());
                }}
              >
                {t("application.jobSearch.clearFiltersButton")}
              </Button>
            </Box>
          </Box>
        </Box>
        <JobAdListComponent
          jobAds={jobAds}
          handleGenerateJobListAdApplication={props.handleGenerateJobListAdApplication}
          searchTotal={searchTotal}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          PAGE_SIZE={PAGE_SIZE}
          resetLocationFilters={resetLocationFilters}
          municipalityFilter={municipalityFilter}
        />
      </CardContent>
    </Card>
  );
};

interface JobAdListComponentProps {
  jobAds: JobAdListItem[];
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
  searchTotal: number | undefined;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  PAGE_SIZE: number;
  resetLocationFilters: () => void;
  municipalityFilter: MunicipalityFilter[];
}

const JobAdListComponent: React.FC<JobAdListComponentProps> = ({
  jobAds,
  handleGenerateJobListAdApplication,
  searchTotal,
  currentPage,
  handlePageChange,
  PAGE_SIZE,
  resetLocationFilters,
  municipalityFilter,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {jobAds.length > 0 ? (
        <List sx={{ backgroundColor: "background.default", p: 2, mt: 2, borderRadius: 2 }}>
          {jobAds.map((jobAd) => (
            <Box sx={{ backgroundColor: "white" }} key={jobAd.id}>
              <JobAdListItemComponent
                key={jobAd.id}
                jobAd={jobAd}
                handleGenerateJobListAdApplication={handleGenerateJobListAdApplication}
              />
            </Box>
          ))}
          {(jobAds.length < PAGE_SIZE || jobAds.length === 0) && (
            <ListItem key="extra-item" sx={{ border: "text.primary", borderRadius: 1, mb: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2, alignItems: "center" }}>
                <LocationOnIcon
                  sx={{
                    fontSize: 60,
                    mb: 2,
                    filter: "grayscale(100%)",
                  }}
                />
                <Typography variant="body1" align="center">
                  {t("application.jobSearch.fewResultsMessage")}
                </Typography>
                <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
                  <Button variant="contained" onClick={resetLocationFilters}>
                    {t("application.jobSearch.turnOffLocationFilterButton")}
                  </Button>
                </Box>
              </Box>
            </ListItem>
          )}
        </List>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
            minHeight: 150,
          }}
        >
          <WorkOutline sx={{ fontSize: 100, color: "background.default" }} />
          <Typography color="textSecondary" sx={{ mt: 2 }}>
            {t("application.jobSearch.noResults")}
          </Typography>
          <ListItem key="extra-item" sx={{ border: "text.primary", borderRadius: 1, mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2, alignItems: "center" }}>
              {searchTotal != undefined && searchTotal > 0 && (
                <LocationOnIcon
                  sx={{
                    fontSize: 60,
                    mb: 2,
                    filter: "grayscale(100%)",
                  }}
                />
              )}
              {municipalityFilter.length > 0 && (
                <>
                  <Typography variant="body1" align="center">
                    {t("application.jobSearch.fewResultsMessage")}
                  </Typography>
                  <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
                    <Button variant="contained" onClick={resetLocationFilters}>
                      {t("application.jobSearch.turnOffLocationFilterButton")}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </ListItem>
        </Box>
      )}
      {jobAds.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {searchTotal != undefined && searchTotal > 0 && (
            <Pagination count={Math.ceil(searchTotal / PAGE_SIZE)} page={currentPage} onChange={handlePageChange} />
          )}
        </Box>
      )}
    </>
  );
};

interface JobAdListItemProps {
  jobAd: JobAdListItem;
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

const JobAdListItemComponent: React.FC<JobAdListItemProps> = ({ jobAd, handleGenerateJobListAdApplication }) => {
  const { t } = useTranslation();

  const handleViewJobDetails = (id: string) => {
    const url = `https://arbetsformedlingen.se/platsbanken/annonser/${id}`;
    window.open(url, "_blank");
  };

  return (
    <ListItem key={jobAd.id} sx={{ border: "text.primary", borderRadius: 1, mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2 }}>
        <Typography variant="h6" sx={{}}>
          {jobAd.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {jobAd.workplace} - {jobAd.location}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {jobAd.role}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGenerateJobListAdApplication(jobAd.id)}
            sx={{ mr: 2 }}
          >
            {t("application.jobSearch.makeApplicationButton")}
          </Button>
          <Button color="secondary" startIcon={<OpenInNew />} onClick={() => handleViewJobDetails(jobAd.id)}>
            {t("application.jobSearch.viewDetailsButton")}
          </Button>
        </Box>
      </Box>
    </ListItem>
  );
};

export default JobListAdSearchForm;
