import { regionFilters } from "../pages/application/methods/PlatsbankenFilterData";
import { MunicipalityFilter } from "./MunicipalityFilter";
import { RegionFilter } from "./RegionFilter";

export interface LocationFilterSelection {
  regions: RegionFilter[];
  municipalities: MunicipalityFilter[];
}

export function getLocationFiltersFromNames(municipalityNames: string[]) {
  const locationFilterSelection: LocationFilterSelection = {
    regions: [],
    municipalities: [],
  };

  municipalityNames.forEach((name: string) => {
    const matchedRegion = regionFilters.find((region) => region.name.toLowerCase() === name.toLowerCase());
    if (matchedRegion) {
      if (!locationFilterSelection.regions.some((r) => r.id === matchedRegion.id)) {
        locationFilterSelection.regions.push(matchedRegion);
      }
      const regionMunicipalityIds = matchedRegion.municipalities.map((m) => m.id);
      locationFilterSelection.municipalities = locationFilterSelection.municipalities.filter(
        (m) => !regionMunicipalityIds.includes(m.id),
      );
    } else {
      let matchedMunicipality: MunicipalityFilter | null = null;
      let parentRegion: RegionFilter | null = null;
      for (const region of regionFilters) {
        const municipality = region.municipalities.find((m) => m.name.toLowerCase() === name.toLowerCase());
        if (municipality) {
          matchedMunicipality = municipality;
          parentRegion = region;
          break;
        }
      }
      if (matchedMunicipality) {
        if (!locationFilterSelection.municipalities.some((m) => m.id === matchedMunicipality!.id)) {
          locationFilterSelection.municipalities.push(matchedMunicipality);
        }

        if (parentRegion) {
          locationFilterSelection.regions = locationFilterSelection.regions.filter((r) => r.id !== parentRegion!.id);
        }
      } else {
        console.warn(`Location "${name}" not found in regions or municipalities`);
      }
    }
  });

  return locationFilterSelection;
}
