import dayjs, { Dayjs } from "dayjs";

export function formatDateToYearMonth(dateStr: string): string {
  const date = dayjs(dateStr);
  const year = date.year();
  const month = date.month() + 1; // Day.js months are 0-indexed
  return `${year} ${month.toString().padStart(2, "0")}`;
}

export function formatDateToString(dateStr: string): string {
  if (!dateStr) {
    return "-";
  }

  const date = dayjs(dateStr);
  const year = date.year();
  const month = (date.month() + 1).toString().padStart(2, "0");
  const day = date.date().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function formatDayjsToString(date: Dayjs | null): string {
  if (!date) {
    return "";
  }

  const year = date.year();
  const month = (date.month() + 1).toString().padStart(2, "0");
  const day = date.date().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
