import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export interface ConfirmDeleteModalProps {
  open: boolean;
  title: string;
  description: string;
  actionColor: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
  onClose: () => void;
  onAction: () => void;
}

export const ConfirmActionDialog: React.FC<ConfirmDeleteModalProps> = ({
  open,
  title,
  description,
  actionColor,
  onClose,
  onAction,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          {t("generic.cancel")}
        </Button>
        <Button
          onClick={() => {
            onAction();
            onClose();
          }}
          variant="contained"
          color={actionColor}
        >
          {t("generic.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
