import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, getMaijaRoutes, PROFILE_ROUTE, RESET_PASSWORD_ROUTE } from "./Routes";
import LoginPage from "./pages/login/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import MaijaSnackbar from "./components/MaijaSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomThemeProvider from "./providers/CustomThemeProvider";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import "./i18n";
import MaijaLoadingPage from "./components/MaijaLoadingPage";
import { AuthProvider, AuthContext } from "./providers/AuthProvider";
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage";

function App() {
  const maijaRoutes = getMaijaRoutes();

  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ isAuthenticated, loading }) => {
          if (loading) {
            return <MaijaLoadingPage isFullscreen />;
          }

          return (
            <CustomThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <Router>
                  <Routes>
                    <Route path={LOGIN_ROUTE.path} element={<LoginPage />} />
                    <Route path={FORGOT_PASSWORD_ROUTE.path} element={<ForgotPasswordPage />} />
                    <Route path={RESET_PASSWORD_ROUTE.path} element={<ResetPasswordPage />} />

                    {isAuthenticated &&
                      maijaRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={
                            <ProtectedRoute route={route}>{React.createElement(route.component)}</ProtectedRoute>
                          }
                        />
                      ))}

                    <Route
                      path="*"
                      element={
                        isAuthenticated ? (
                          <Navigate to={PROFILE_ROUTE.path} replace />
                        ) : (
                          <Navigate to={LOGIN_ROUTE.path} replace />
                        )
                      }
                    />
                  </Routes>
                  <MaijaSnackbar />
                </Router>
              </LocalizationProvider>
            </CustomThemeProvider>
          );
        }}
      </AuthContext.Consumer>
    </AuthProvider>
  );
}

export default App;
