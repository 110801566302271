import { Box, Button, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { markApplicationAsApplied } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { setPendingApplication } from "../../../reducers/applicantDetailsPageSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";

interface ApplicationActionModalProps {
  isOpen: boolean;
  applicationId: string;
  actionType: ApplicationActionType;
  handleClose: () => void;
}

export enum ApplicationActionType {
  Email = "EMAIL",
  Download = "DOWNLOAD",
}

const ApplicationActionModal: React.FC<ApplicationActionModalProps> = ({
  isOpen,
  actionType,
  applicationId,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useAppDispatch();

  const handleMarkAsApplied = () => {
    setIsUpdating(true);
    markApplicationAsApplied(applicationId, true)
      .then((response) => {
        dispatch(
          setPendingApplication({
            applicationId: response.id,
            pendingApplication: response,
          }),
        );
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          showSnackbar({
            message: t("applicationDetails.actionModal.errorUpdating"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const actionText =
    actionType === ApplicationActionType.Email
      ? t("applicationDetails.actionModal.descriptionEmail")
      : t("applicationDetails.actionModal.descriptionDownload");

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} maxWidth="700px">
      <Typography id="application-action-modal-title" variant="h6" component="h2">
        {t("applicationDetails.actionModal.title")}
      </Typography>
      <Typography id="application-action-modal-description" sx={{ mt: 2 }}>
        {actionText}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="success"
          onClick={handleMarkAsApplied}
          sx={{ mx: 2 }}
          disabled={isUpdating}
          startIcon={isUpdating ? <CircularProgress size={24} color="inherit" /> : null}
        >
          {t("applicationDetails.actionModal.appliedButton")}
        </Button>
        <Button variant="text" color="primary" onClick={handleClose} sx={{ mx: 2 }}>
          {t("applicationDetails.actionModal.doItLaterButton")}
        </Button>
      </Box>
    </MaijaModal>
  );
};

export default ApplicationActionModal;
