import React, { useMemo } from "react";
import { red, green, yellow } from "@mui/material/colors";
import { Box, Typography, Tooltip } from "@mui/material";
import { WorkExperience } from "../types/WorkExperience";
import { Education } from "../types/Education";
import { formatDateToString } from "../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface MaijaExperienceTimelineProps {
  workExperiences: WorkExperience[];
  educations: Education[];
}

const MaijaExperienceTimeline: React.FC<MaijaExperienceTimelineProps> = ({ workExperiences, educations }) => {
  const { t } = useTranslation();

  const { timeline } = useMemo(
    () => generateTimelineBoxes(workExperiences, educations, t),
    [workExperiences, educations, t],
  );

  const getEarliestYear = () => {
    const workYears = workExperiences.map((exp) => new Date(exp.fromDate).getFullYear());
    const educationYears = educations.map((edu) => new Date(edu.fromDate).getFullYear());

    const allYears = [...workYears, ...educationYears];

    return Math.min(...allYears);
  };

  const startYear = getEarliestYear();
  const endYear = new Date().getFullYear();

  const calculateMilestones = (startYear: number, endYear: number): number[] => {
    const totalYears = endYear - startYear;
    if (totalYears <= 20) {
      return Array.from({ length: totalYears + 1 }, (_, index) => startYear + index);
    } else {
      const interval = Math.ceil(totalYears / 20);
      return Array.from({ length: 21 }, (_, index) => startYear + index * interval).filter((year) => year <= endYear);
    }
  };

  const milestones = calculateMilestones(startYear, endYear);

  const startDate = new Date(startYear, 0, 1);
  const endDate = new Date();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" padding="2%">
      <Box display="flex" position="relative" justifyContent="center" width="100%" mb={5} sx={{ height: 120 }}>
        {timeline}
      </Box>

      <TimelineAxis startDate={startDate} endDate={endDate} milestones={milestones} />
    </Box>
  );
};

const generateTimelineBoxes = (
  workExperiences: WorkExperience[],
  educations: Education[],
  t: TFunction,
): { timeline: JSX.Element[]; labels: JSX.Element[] } => {
  const timeline: JSX.Element[] = [];
  const labels: JSX.Element[] = [];

  const combinedExperiences = [...workExperiences, ...educations].sort(
    (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
  );

  const getEarliestYear = () => {
    const workYears = workExperiences.map((exp) => new Date(exp.fromDate).getFullYear());
    const educationYears = educations.map((edu) => new Date(edu.fromDate).getFullYear());

    const allYears = [...workYears, ...educationYears];

    return Math.min(...allYears);
  };

  const firstYearDate = new Date(getEarliestYear(), 0, 1);

  const todaysDate = new Date();
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const totalDays = (todaysDate.getTime() - firstYearDate.getTime()) / millisecondsInDay;

  let lastExperienceEndDate = firstYearDate;

  combinedExperiences.forEach((experience, index) => {
    const isWork = "workplace" in experience;
    const type = isWork ? "work" : "education";
    const id = experience.id || `${type}-${index}`;

    const startDate = new Date(experience.fromDate);
    const endDate = experience.toDate ? new Date(experience.toDate) : todaysDate;

    const duration = (endDate.getTime() - startDate.getTime()) / millisecondsInDay;
    const left = ((startDate.getTime() - firstYearDate.getTime()) / (millisecondsInDay * totalDays)) * 100;
    const width = (duration / totalDays) * 100;

    let overlaps = 0;
    for (let i = 0; i < index; i++) {
      const prevExperience = combinedExperiences[i];
      const prevStart = new Date(prevExperience.fromDate);
      const prevEnd = prevExperience.toDate ? new Date(prevExperience.toDate) : todaysDate;
      const prevType = "workplace" in prevExperience ? "work" : "education";

      if (
        type === prevType &&
        ((startDate >= prevStart && startDate <= prevEnd) ||
          (endDate >= prevStart && endDate <= prevEnd) ||
          (startDate <= prevStart && endDate >= prevEnd))
      ) {
        overlaps += 1;
      }
    }

    const baseColor = isWork ? green : yellow;
    const colorShades = [500, 600, 700, 800, 900];
    const colorIndex = Math.min(overlaps, colorShades.length - 1);
    const boxColor = baseColor[colorShades[colorIndex] as keyof typeof baseColor];

    if (startDate > lastExperienceEndDate && index > 0) {
      const gapDuration = (startDate.getTime() - lastExperienceEndDate.getTime()) / millisecondsInDay;
      if (gapDuration > 30) {
        const gapLeft =
          ((lastExperienceEndDate.getTime() - firstYearDate.getTime()) / (millisecondsInDay * totalDays)) * 100;
        const gapWidth = (gapDuration / totalDays) * 100;

        timeline.push(
          <Tooltip
            key={`gap-${index}`}
            title={
              <>
                <Typography variant="body2" color="inherit">
                  {t("profilePage.experienceTimeline.unemployment")}
                </Typography>
                <Typography variant="body2" color="inherit">
                  {formatDateToString(lastExperienceEndDate.toISOString())} -{" "}
                  {formatDateToString(startDate.toISOString())}
                </Typography>
              </>
            }
          >
            <Box
              bgcolor={red[500]}
              width={`${gapWidth}%`}
              height="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              m={0}
              mt={"80px"}
              sx={{
                p: 1,
                borderRadius: 1,
                transition: "background-color 0.3s ease",
                position: "absolute",
                left: `${gapLeft}%`,
                "&:hover": {
                  backgroundColor: red[700],
                },
              }}
            >
              {gapDuration > 100 && (
                <Typography variant="caption" color="inherit" noWrap sx={{ maxWidth: "100%" }}>
                  {t("profilePage.experienceTimeline.unemployment")}
                </Typography>
              )}
            </Box>
          </Tooltip>,
        );
      }
    }

    if (endDate > lastExperienceEndDate) {
      lastExperienceEndDate = endDate;
    }

    timeline.push(
      <Tooltip
        key={id}
        title={
          <>
            <Typography variant="body2" color="inherit">
              {isWork ? experience.workplace : experience.provider}
            </Typography>
            <Typography variant="body2" color="inherit">
              {isWork ? experience.position : experience.subject}
            </Typography>
            <Typography variant="body2" color="inherit">
              {isWork ? t("profilePage.experienceTimeline.work") : t("profilePage.experienceTimeline.education")}
            </Typography>
            <Typography variant="body2" color="inherit">
              {formatDateToString(startDate.toISOString())} -{" "}
              {experience.toDate ? formatDateToString(endDate.toISOString()) : t("profilePage.education.present")}
            </Typography>
          </>
        }
      >
        <Box
          bgcolor={boxColor}
          width={`${width}%`}
          height="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          m={0}
          mt={isWork ? "0" : "40px"}
          sx={{
            p: 1,
            borderRadius: 1,
            transition: "background-color 0.3s ease",
            position: "absolute",
            left: `${left}%`,
            "&:hover": {
              backgroundColor: baseColor[900],
            },
          }}
        >
          {duration > 100 && (
            <Typography variant="caption" color="black" noWrap sx={{ maxWidth: "100%" }}>
              {isWork ? experience.position : experience.subject}
            </Typography>
          )}
        </Box>
      </Tooltip>,
    );

    labels.push(
      <Box key={`label-${startDate.getFullYear()}-${startDate.getMonth()}`} m={1}>
        <Typography variant="caption" color="inherit">
          {startDate.getFullYear()}-{startDate.toLocaleString("default", { month: "short" })}
        </Typography>
      </Box>,
    );
  });

  if (lastExperienceEndDate < todaysDate) {
    const gapDuration = (todaysDate.getTime() - lastExperienceEndDate.getTime()) / millisecondsInDay;
    if (gapDuration > 30) {
      const gapLeft =
        ((lastExperienceEndDate.getTime() - firstYearDate.getTime()) / (millisecondsInDay * totalDays)) * 100;
      const gapWidth = (gapDuration / totalDays) * 100;

      timeline.push(
        <Tooltip
          key={`gap-end`}
          title={
            <>
              <Typography variant="body2" color="inherit">
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
              <Typography variant="body2" color="inherit">
                {formatDateToString(lastExperienceEndDate.toISOString())} -{" "}
                {formatDateToString(todaysDate.toISOString())}
              </Typography>
            </>
          }
        >
          <Box
            bgcolor={red[500]}
            width={`${gapWidth}%`}
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={0}
            mt={"80px"}
            sx={{
              p: 1,
              borderRadius: 1,
              transition: "background-color 0.3s ease",
              position: "absolute",
              left: `${gapLeft}%`,
              "&:hover": {
                backgroundColor: red[700],
              },
            }}
          >
            {gapDuration > 100 && (
              <Typography variant="caption" color="inherit" noWrap sx={{ maxWidth: "100%" }}>
                {t("profilePage.experienceTimeline.unemployment")}
              </Typography>
            )}
          </Box>
        </Tooltip>,
      );
    }
  }

  return { timeline, labels };
};

interface TimelineProps {
  startDate: Date;
  endDate: Date;
  milestones: number[];
}

const TimelineAxis: React.FC<TimelineProps> = ({ startDate, endDate, milestones }) => {
  const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const calculateLeftPosition = (year: number) => {
    return ((year - startDate.getFullYear()) / (totalDays / 365.25)) * 100;
  };

  return (
    <Box sx={{ position: "relative", width: "100%", mt: 2, height: 30 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 3,
          backgroundColor: "black",
          position: "relative",
          top: 15,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "-4px",
            top: "-5px",
            borderLeft: "15px solid black",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
          }}
        />
      </Box>

      {milestones.map((year) => (
        <Box
          key={year}
          sx={{
            position: "absolute",
            left: `${calculateLeftPosition(year)}%`,
            top: "-10px",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              mt: 0,
              top: -10,
              transform: "translateX(-50%)",
            }}
          >
            {year}
          </Typography>
          <Box
            sx={{
              width: 2,
              height: 15,
              backgroundColor: "black",
              mt: 1,
              position: "relative",
              top: -15,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MaijaExperienceTimeline;
