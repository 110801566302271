import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig, AxiosHeaders, AxiosRequestHeaders } from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL } from "../config";
import { performLogout } from "../utils/LogoutUtil";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const method = config.method?.toLowerCase();
    if (method === "post" || method === "put" || method === "delete" || method === "patch" || method === "options") {
      const csrfToken = Cookies.get("csrf_access_token");
      if (csrfToken) {
        if (!config.headers) {
          config.headers = AxiosHeaders.from({});
        }

        if (config.headers instanceof AxiosHeaders) {
          config.headers.set("X-CSRF-TOKEN", csrfToken);
        } else {
          (config.headers as AxiosRequestHeaders)["X-CSRF-TOKEN"] = csrfToken;
        }
      }
    }

    return config;
  },
  (error: AxiosError) => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      await performLogout();
    }
    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  },
);

export default axiosInstance;
