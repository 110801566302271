import { Navigate } from "react-router-dom";
import MaijaRoute, { LOGIN_ROUTE, PROFILE_ROUTE, APPLICATION_ROUTE, APPLICATION_LIST_ROUTE } from "../Routes";
import ApplicantAppBar from "./ApplicantAppBar";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { getSettings } from "../pages/login/LoginRepository";
import axiosInstance from "../utils/AxiosInstance";
import { setLoggedInUser } from "../reducers/userSlice";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
  setSettingsLoaded as setReduxSettingsLoaded,
} from "../reducers/themeSlice";
import MaijaLoadingPage from "./MaijaLoadingPage";
import { useAppDispatch } from "../hooks";
import { User } from "../types/User";

type ProtectedRouteProps = {
  route: MaijaRoute;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAndSetApplicantSettings = async (companyId: string | undefined) => {
      if (!companyId) {
        dispatch(setReduxSettingsLoaded(true));
        return;
      }
      try {
        const settings = await getSettings(companyId);
        if (settings) {
          dispatch(setReduxPrimaryColor(settings.primary_color || "#FFFFFF"));
          dispatch(setReduxSecondaryColor(settings.secondary_color || "#FFFFFF"));
          dispatch(setReduxTertiaryColor(settings.tertiary_color || "#FFFFFF"));
          dispatch(setReduxLogo(settings.logo ?? null));
          dispatch(setReduxLoginImage(settings.login_image ?? null));
        }
      } catch (error) {
        console.error("Failed to load company settings:", error);
      } finally {
        dispatch(setReduxSettingsLoaded(true));
      }
    };
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get<User>("/current_user");
        setUser(response.data);
        dispatch(setLoggedInUser(response.data));
        sessionStorage.setItem("currentUser", JSON.stringify(response.data));
        await fetchAndSetApplicantSettings(response.data.companyId);
      } catch (error) {
        setUser(null);
        dispatch(setReduxSettingsLoaded(true));
      } finally {
        setLoading(false);
      }
    };
    if (!user) {
      fetchCurrentUser();
    } else {
      fetchAndSetApplicantSettings(user.companyId);
      setLoading(false);
    }
  }, [user, setUser, dispatch]);

  if (loading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  if (!user) {
    return <Navigate to={LOGIN_ROUTE.path} replace />;
  }

  const toolbarRoutes = [PROFILE_ROUTE, APPLICATION_ROUTE, APPLICATION_LIST_ROUTE];

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <ApplicantAppBar routes={toolbarRoutes} />
      <div style={{ flexGrow: 1, overflow: "auto" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;
