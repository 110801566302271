import { Button, Checkbox, List, ListItem, ListItemText, Popover } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useState } from "react";
import { RegionFilter } from "../../../types/RegionFilter";
import { MunicipalityFilter } from "../../../types/MunicipalityFilter";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { regionFilters } from "./PlatsbankenFilterData";
import { Box } from "@mui/system";
import { setSearchMunicipalityFilter, setSearchRegionFilter } from "../../../reducers/applicationPageSlice";
import { useTranslation } from "react-i18next";

interface LocationFilterSelectorProps {
  onSelect?: (selectedLocation: string[]) => void;
}

function regionContainsSelectedMunicipality(
  region: RegionFilter,
  allSelectedMunicipalities: MunicipalityFilter[],
): boolean {
  return region.municipalities.some((municipality) => allSelectedMunicipalities.includes(municipality));
}

function hasLocationFilter(regions: RegionFilter[], municipalities: MunicipalityFilter[]): boolean {
  return regions.length > 0 || municipalities.length > 0;
}

function hasAllMunicipalitiesInRegion(region: RegionFilter, allSelectedMunicipalities: MunicipalityFilter[]): boolean {
  return region.municipalities.every((municipality) => allSelectedMunicipalities.includes(municipality));
}

export const LocationFilterSelector: React.FC<LocationFilterSelectorProps> = ({ onSelect }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const selectedRegions = useAppSelector((state) => state.applicationPage.searchRegionFilter);
  const selectedMunicipalities = useAppSelector((state) => state.applicationPage.searchMunicipalityFilter);
  const [activeRegion, setActiveRegion] = useState<RegionFilter | null>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    dispatch(setSearchRegionFilter(selectedRegions));
    dispatch(setSearchMunicipalityFilter(selectedMunicipalities));
  };

  const handleRegionClick = (region: RegionFilter) => {
    setActiveRegion(activeRegion === region ? null : region);
  };

  const setSelectedRegions = (regions: RegionFilter[]) => {
    dispatch(setSearchRegionFilter(regions));
  };
  const setSelectedMunicipalities = (municipalities: MunicipalityFilter[]) => {
    dispatch(setSearchMunicipalityFilter(municipalities));
  };

  const handleSelectAllRegionClick = (region: RegionFilter) => {
    const isCurrentlySelected = selectedRegions.some((r) => r.id === region.id);
    let updatedMunicipalities = [...selectedMunicipalities];

    if (isCurrentlySelected) {
      setSelectedRegions(selectedRegions.filter((r) => r.id !== region.id));
      const regionMunicipalityIds = region.municipalities.map((m) => m.id);
      updatedMunicipalities = updatedMunicipalities.filter((m) => !regionMunicipalityIds.includes(m.id));
    } else {
      setSelectedRegions([...selectedRegions, region]);
      const newMunicipalities = region.municipalities.filter(
        (m) => !updatedMunicipalities.some((selectedM) => selectedM.id === m.id),
      );
      updatedMunicipalities = [...updatedMunicipalities, ...newMunicipalities];
    }

    if (onSelect) {
      const selectedLocation = updatedMunicipalities.map((m) => m.name);
      onSelect(selectedLocation);
    }
    setSelectedMunicipalities(updatedMunicipalities);
  };

  const handleMunicipalityToggle = (municipality: MunicipalityFilter) => {
    let updatedMunicipalities = [...selectedMunicipalities];
    const isCurrentlySelected = selectedMunicipalities.some((m) => m.id === municipality.id);

    if (isCurrentlySelected) {
      updatedMunicipalities = updatedMunicipalities.filter((m) => m.id !== municipality.id);

      // Check if the municipality is part of any selected region
      const regionContainingMunicipality = selectedRegions.find((region) =>
        region.municipalities.some((m) => m.id === municipality.id),
      );
      if (regionContainingMunicipality) {
        // Remove the region from selected regions
        setSelectedRegions(selectedRegions.filter((region) => region.id !== regionContainingMunicipality.id));

        // Add all municipalities of that region to selected municipalities except the toggled one
        const newMunicipalities = regionContainingMunicipality.municipalities
          .filter((m) => m.id !== municipality.id)
          .map((m) => ({ ...m }));

        updatedMunicipalities = [...updatedMunicipalities, ...newMunicipalities];
      }
    } else {
      // Add the toggled municipality
      updatedMunicipalities.push(municipality);
      // Check if all municipalities of any region are selected
      const regionContainingMunicipality = regionFilters.find((region) =>
        region.municipalities.some((m) => m.id === municipality.id),
      );
      if (regionContainingMunicipality) {
        const allMunicipalitiesSelected = regionContainingMunicipality.municipalities.every((m) =>
          updatedMunicipalities.some((selectedM) => selectedM.id === m.id),
        );
        if (allMunicipalitiesSelected) {
          setSelectedRegions([...selectedRegions, regionContainingMunicipality]);
        }
      }
    }

    // If onSelect is provided, call it with the selected location
    if (onSelect) {
      const selectedLocation = updatedMunicipalities.map((m) => m.name);
      onSelect(selectedLocation);
    }

    setSelectedMunicipalities(updatedMunicipalities);
  };

  const open = Boolean(anchorEl);
  const id = open ? "location-selector-popover" : undefined;

  return (
    <div>
      <Button
        variant={anchorEl || hasLocationFilter(selectedRegions, selectedMunicipalities) ? "outlined" : "contained"}
        onClick={handleButtonClick}
        data-cy="location-filter-button"
      >
        {hasLocationFilter(selectedRegions, selectedMunicipalities) && (
          <Box width={10} height={10} borderRadius="50%" bgcolor={"orange"} mr={2}></Box>
        )}
        {t("application.jobSearch.location")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableScrollLock={true}
      >
        <div style={{ display: "flex" }}>
          <List style={{ minWidth: 150 }}>
            {regionFilters.map((region) => (
              <div key={region.name}>
                <ListItem button onClick={() => handleRegionClick(region)}>
                  <ListItemText primary={region.name} data-cy={`${region.name}`} />
                  {regionContainsSelectedMunicipality(region, selectedMunicipalities) && (
                    <Box width={10} height={10} borderRadius="50%" bgcolor={"orange"} mr={2}></Box>
                  )}
                  {activeRegion === region ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </div>
            ))}
          </List>
          {activeRegion && (
            <List style={{ minWidth: 200 }}>
              <ListItem button onClick={() => handleSelectAllRegionClick(activeRegion)}>
                <Checkbox
                  checked={selectedRegions.includes(activeRegion)}
                  indeterminate={
                    regionContainsSelectedMunicipality(activeRegion, selectedMunicipalities) &&
                    !hasAllMunicipalitiesInRegion(activeRegion, selectedMunicipalities) &&
                    !selectedRegions.includes(activeRegion)
                  }
                />
                <ListItemText
                  primary={t("application.jobSearch.selectAllMunicipalities")}
                  data-cy={`all-municipalities`}
                />
              </ListItem>
              {activeRegion.municipalities.map((municipality) => (
                <ListItem key={municipality.id} button onClick={() => handleMunicipalityToggle(municipality)}>
                  <Checkbox checked={selectedMunicipalities.includes(municipality)} />
                  <ListItemText primary={municipality.name} data-cy={`${municipality.name}`} />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Popover>
    </div>
  );
};
