import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  logo: string | null;
  loginImage: string | null;
  settingsLoaded: boolean;
}

const initialState: ThemeState = {
  primaryColor: "#003366",
  secondaryColor: "#008080",
  tertiaryColor: "#ffffff",
  logo: null,
  loginImage: null,
  settingsLoaded: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setPrimaryColor(state, action: PayloadAction<string>) {
      state.primaryColor = action.payload;
    },
    setSecondaryColor(state, action: PayloadAction<string>) {
      state.secondaryColor = action.payload;
    },
    setTertiaryColor(state, action: PayloadAction<string>) {
      state.tertiaryColor = action.payload;
    },
    setLogo(state, action: PayloadAction<string | null>) {
      state.logo = action.payload;
    },
    setLoginImage(state, action: PayloadAction<string | null>) {
      state.loginImage = action.payload;
    },
    setSettingsLoaded(state, action: PayloadAction<boolean>) {
      state.settingsLoaded = action.payload;
    },
    clearTheme: (state) => {
      state.primaryColor = "#003366";
      state.secondaryColor = "#008080";
      state.tertiaryColor = "#ffffff";
      state.logo = null;
      state.loginImage = null;
    },
  },
});

export const {
  setPrimaryColor,
  setSecondaryColor,
  setTertiaryColor,
  setLogo,
  setLoginImage,
  setSettingsLoaded,
  clearTheme,
} = themeSlice.actions;

export default themeSlice.reducer;
