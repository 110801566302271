import React, { useEffect, useMemo, useState } from "react";
import { Typography, Paper, Box, Skeleton } from "@mui/material";
import { ApplicantSupportInfo } from "../../types/ApplicationSupportInfo";
import { TFunction } from "i18next";
import { PlatsbankenJobsCounts } from "../../types/PlatsbankenJobsCounts";
import { fetchPlatsbankenJobCounts } from "./ProfilePageRepository";

interface PlatsbankenJobsCountPaperProps {
  supportInfo: ApplicantSupportInfo | null | undefined;
  t: TFunction;
}

const PlatsbankenJobsCountPaper: React.FC<PlatsbankenJobsCountPaperProps> = ({ t, supportInfo }) => {
  const [jobCounts, setJobCounts] = useState<PlatsbankenJobsCounts | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchJobCounts = async () => {
      try {
        const counts = await fetchPlatsbankenJobCounts();
        setJobCounts(counts);
        setIsLoading(false);
      } catch (error) {
        setError("Failed to fetch job counts.");
        setIsLoading(false);
      }
    };

    if (supportInfo?.firstPreferredJob || supportInfo?.secondPreferredJob || supportInfo?.thirdPreferredJob) {
      fetchJobCounts();
    }
  }, [supportInfo]);

  const totalJobsCount = useMemo(() => {
    return (
      (jobCounts?.firstPreferredJob || 0) + (jobCounts?.secondPreferredJob || 0) + (jobCounts?.thirdPreferredJob || 0)
    );
  }, [jobCounts]);

  const renderPreferredJob = (jobName: string | undefined | null, amount?: number) => {
    if (!jobName) return null;
    return (
      <Box key={jobName} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
          {jobName}:
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" width={40} />
        ) : (
          <Typography variant="body1" fontWeight="bold">
            {amount}
          </Typography>
        )}
      </Box>
    );
  };

  if (
    error ||
    (!supportInfo?.firstPreferredJob && !supportInfo?.secondPreferredJob && !supportInfo?.thirdPreferredJob)
  ) {
    return null;
  }

  return (
    <Paper sx={{ borderRadius: 6, mb: 5, p: 7 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        {isLoading ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography variant="h5" data-cy="platsbanken-jobs-title">
            {t("profilePage.platsbankenJobs.title")}: {totalJobsCount}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {renderPreferredJob(supportInfo?.firstPreferredJob, jobCounts?.firstPreferredJob)}
        {renderPreferredJob(supportInfo?.secondPreferredJob, jobCounts?.secondPreferredJob)}
        {renderPreferredJob(supportInfo?.thirdPreferredJob, jobCounts?.thirdPreferredJob)}
      </Box>
    </Paper>
  );
};

export default PlatsbankenJobsCountPaper;
