import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { RootState } from "../../store";
import { Delete, Edit } from "@mui/icons-material";
import { WorkExperience } from "../../types/WorkExperience";
import WorkExperienceModal from "./WorkExperienceModal";
import { deleteWorkExperience as apiDeleteWorkExperience } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteWorkExperience } from "../../reducers/profilePageSlice";
import { formatDateToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";

const WorkExperiencesComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const workExperiences = useSelector((state: RootState) => state.profilePage.workExperiences);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentWorkExperience, setCurrentWorkExperience] = useState<WorkExperience | null>(null);

  const handleAddClick = () => {
    setCurrentWorkExperience(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (workExperience: WorkExperience) => {
    setCurrentWorkExperience(workExperience);
    setIsModalOpen(true);
  };

  const onDelete = async (id: string) => {
    try {
      await apiDeleteWorkExperience(id);
      dispatch(deleteWorkExperience(id));
      dispatch(showSnackbar({ message: t("profilePage.workExperience.deleteSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.workExperience.deleteError"), severity: "error" }));
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {workExperiences && workExperiences.length > 0 ? (
        <WorkExperiencesList
          workExperiences={workExperiences}
          onAdd={handleAddClick}
          onEdit={handleEditClick}
          onDelete={onDelete}
        />
      ) : (
        <EmptyState onAdd={handleAddClick} />
      )}
      <WorkExperienceModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialWorkExperience={currentWorkExperience}
      />
    </Box>
  );
};

interface WorkExperiencesListProps {
  workExperiences: WorkExperience[];
  onAdd: () => void;
  onEdit: (workExperience: WorkExperience) => void;
  onDelete: (id: string) => void;
}

const WorkExperiencesList: React.FC<WorkExperiencesListProps> = ({ workExperiences, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.workExperience.title")}
      </Typography>
      <List sx={{ flex: 1 }}>
        {workExperiences.map((workExperience, index) => (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 0.5, pl: 0 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: -2 }}>
                {workExperience.position}
              </Typography>
              <ListItemText
                primary={`${workExperience.workplace}`}
                secondary={`${formatDateToString(workExperience.fromDate)} - ${
                  workExperience.toDate
                    ? formatDateToString(workExperience.toDate)
                    : t("profilePage.workExperience.present")
                }`}
              />
            </Box>
            <Box>
              <IconButton edge="end" aria-label="edit" sx={{ mr: 1 }} onClick={() => onEdit(workExperience)}>
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => onDelete(workExperience.id)}>
                <Delete />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button
        sx={{ mt: 2, minWidth: "30%", alignSelf: "center", width: "auto" }}
        variant="contained"
        color="primary"
        disableRipple
        onClick={onAdd}
        data-cy="add-work-experience-button"
      >
        {t("profilePage.workExperience.addButton")}
      </Button>
    </Box>
  );
};

interface EmptyStateProps {
  onAdd: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.workExperience.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.workExperience.emptyStateMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={onAdd} disableRipple data-cy="add-work-experience-button">
        {t("profilePage.workExperience.addButton")}
      </Button>
    </Box>
  );
};

export default WorkExperiencesComponent;
