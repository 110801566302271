import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react";

export interface MaijaLottieAnimationProps {
  animationData: any; // eslint-disable-line
  size?: number;
  show: boolean;
  loop: boolean;
  LabelComponent?: React.ReactNode;
}

const MaijaLottieAnimation: React.FC<MaijaLottieAnimationProps> = ({
  animationData,
  size,
  show,
  loop,
  LabelComponent,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9998,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Lottie
        animationData={animationData}
        loop={loop}
        autoplay={true}
        style={{ height: size ?? 300, width: size ?? 300 }}
      />
      {LabelComponent && <div>{LabelComponent}</div>}
    </Box>
  );
};

export default MaijaLottieAnimation;
