import axiosInstance from "./AxiosInstance";
import store from "../store";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearApplicationPage } from "../reducers/applicationPageSlice";
import { clearProfilePage } from "../reducers/profilePageSlice";
import { clearApplicationDetailsPage } from "../reducers/applicantDetailsPageSlice";
import { clearApplicationsListPage } from "../reducers/applicationsListPageSlice";

export async function performLogout() {
  const dispatch = store.dispatch;

  try {
    await axiosInstance.post("/logout");
  } catch (error) {
    console.error("Logout request failed:", error);
  } finally {
    dispatch(clearLoggedInUser());
    dispatch(clearProfilePage());
    dispatch(clearApplicationPage());
    dispatch(clearApplicationDetailsPage());
    dispatch(clearApplicationsListPage());
  }
}
