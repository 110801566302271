import { Box, Skeleton, Paper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { getResumePreview } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";

interface ResumePreviewModalProps {
  isOpen: boolean;
  resumeId: string;
  handleClose: () => void;
}

const calculateWidthFromHeight = (height: number) => {
  return height / 1.414; // A4 aspect ratio (21cm/29.7cm)
};

const A4Paper = styled(Paper)(() => ({
  height: "120vh",
  width: `${calculateWidthFromHeight(window.innerHeight * 1.2)}px`,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  borderRadius: 2,
}));

const ResumePreviewModal: React.FC<ResumePreviewModalProps> = ({ isOpen, resumeId, handleClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [resumeThumbnails, setResumeThumbnails] = useState<string[]>([]);

  useEffect(() => {
    if (resumeId && isOpen) {
      setIsLoading(true);
      const fetchResumeThumbnails = async () => {
        try {
          const resumeThumbs = await getResumePreview(resumeId);
          setResumeThumbnails(resumeThumbs);
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("applicationDetails.resumePreviewModal.errorLoading"),
              severity: "error",
            }),
          );
          handleClose();
        } finally {
          setIsLoading(false);
        }
      };

      fetchResumeThumbnails();
    }
  }, [isOpen]);

  return (
    <MaijaModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={calculateWidthFromHeight(window.innerHeight * 0.8).toString()}
      maxHeight="95vh"
    >
      {isLoading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          width={calculateWidthFromHeight(window.innerHeight * 1.2)}
          height="120vh"
        />
      ) : (
        resumeThumbnails.map((thumbnail, index) => {
          const isNotLastItem = index !== resumeThumbnails.length - 1;
          return (
            <Box key={`box-${index}`} sx={{ maxHeight: "120vh" }}>
              <A4Paper key={index} elevation={10} sx={{ mb: isNotLastItem ? 2 : 0 }}>
                <img
                  src={`data:image/jpeg;base64,${thumbnail}`}
                  alt={`${t("applicationDetails.resumePreviewModal.documentThumbnail")} ${index + 1}`}
                  style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                />
              </A4Paper>
            </Box>
          );
        })
      )}
    </MaijaModal>
  );
};

export default ResumePreviewModal;
