import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { RootState } from "../../store";
import { Delete, Edit } from "@mui/icons-material";
import { Skill } from "../../types/Skill";
import SkillModal from "./SkillModal";
import { deleteSkill as apiDeleteSkill } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteSkill } from "../../reducers/profilePageSlice";
import { useTranslation } from "react-i18next";

const SkillsComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const skills = useSelector((state: RootState) => state.profilePage.skills);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSkill, setCurrentSkill] = useState<Skill | null>(null);

  const handleAddClick = () => {
    setCurrentSkill(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (skill: Skill) => {
    setCurrentSkill(skill);
    setIsModalOpen(true);
  };

  const onDelete = async (id: string) => {
    try {
      await apiDeleteSkill(id);
      dispatch(deleteSkill(id));
      dispatch(showSnackbar({ message: t("profilePage.skill.deleteSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.skill.deleteError"), severity: "error" }));
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {skills && skills.length > 0 ? (
        <SkillsList skills={skills} onAdd={handleAddClick} onEdit={handleEditClick} onDelete={onDelete} />
      ) : (
        <EmptyState onAdd={handleAddClick} />
      )}
      <SkillModal open={isModalOpen} onClose={() => setIsModalOpen(false)} initialSkill={currentSkill} />
    </Box>
  );
};

interface SkillsListProps {
  skills: Skill[];
  onAdd: () => void;
  onEdit: (skill: Skill) => void;
  onDelete: (id: string) => void;
}

const SkillsList: React.FC<SkillsListProps> = ({ skills, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.skill.title")}
      </Typography>
      <List sx={{ flex: 1 }}>
        {skills.map((skill, index) => (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 0.5, pl: 0 }}
          >
            <ListItemText primary={skill.name} />
            <Box>
              <IconButton edge="end" aria-label="edit" sx={{ mr: 1 }} onClick={() => onEdit(skill)}>
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => onDelete(skill.id)}>
                <Delete />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button
        sx={{ mt: 2, minWidth: "30%", alignSelf: "center", width: "auto" }}
        variant="contained"
        color="primary"
        disableRipple
        onClick={onAdd}
        data-cy="add-skill-button"
      >
        {t("profilePage.skill.addButton")}
      </Button>
    </Box>
  );
};

interface EmptyStateProps {
  onAdd: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.skill.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.skill.emptyStateMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={onAdd} disableRipple data-cy="add-skill-button">
        {t("profilePage.skill.addButton")}
      </Button>
    </Box>
  );
};

export default SkillsComponent;
